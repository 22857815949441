<template>
    <v-card
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row class="ma-0">
            <v-col cols="12" lg="9" xl="6" class="pa-0">
                <a-form :submit="save">
                    <v-card-title class="mb-4">Whitelabel Options</v-card-title>

                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="business_name"
                                    label="Business Name"
                                    rules="required"
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="product_name"
                                    label="Product Name"
                                    rules="required"
                                    hint="This will appear in various places throughout the portal. If you do not supply one, a generic name will be used."
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <a-text-input
                                    v-model="subdomain"
                                    label="Subdomain"
                                    rules="required|max:255|alpha_dash|availability:/users/check_subdomain_availability"
                                    :debounce="500"
                                    :hint="subdomainHint"
                                    persistent-hint
                                />
                            </v-col>
                            <v-col v-if="subdomain" cols="12" md="6">
                                <v-btn
                                    class="text-center text-caption shrink-overflow"
                                    :href="salesFunnelUrl"
                                    color="primary"
                                    block
                                    x-large
                                >
                                    Your Amp Sales Funnel
                                    <div class="no-overflow">
                                        {{ salesFunnelDomain }}
                                    </div>
                                </v-btn>
                                <div
                                    class="theme--light v-messages v-messages__message pt-3"
                                >
                                    If you'd prefer to set up your portal on
                                    your own custom domain, please open
                                    <router-link :to="portal">this</router-link>
                                    page for more details.
                                </div>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="reseller_currency"
                                    filled
                                    name="reseller_currency"
                                    :items="currencyOptions"
                                    item-text="label"
                                    item-value="value"
                                    label="Currency"
                                    color="secondary"
                                    hint="Your clients will see the prices and pay you in this currency."
                                    persistent-hint
                                />
                            </v-col>
                            <v-col v-if="!isClientCabin" cols="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_authoring_credit_price
                                            .value
                                    "
                                    label="Client Authoring Price"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_authoring_credit_price'
                                        )
                                    "
                                    :hint="clientAuthoringPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-card-title class="pl-0 pb-2">
                            Amp Campaigns Prices
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        href="/app/proposal"
                                        target="_blank"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>
                                            arrow-up-right-from-square
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Click to land on Amp Sales Page</span>
                            </v-tooltip>
                        </v-card-title>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_content_campaign_price
                                            .value
                                    "
                                    label="Price To Sell Amp Campaigns"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_content_campaign_price'
                                        )
                                    "
                                    :hint="contentCampaignPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_recurring_content_campaign_price
                                            .value
                                    "
                                    label="Price To Sell Recurring Amp Campaigns"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_recurring_content_campaign_price'
                                        )
                                    "
                                    :hint="recurringContentCampaignPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_recurring_four_content_campaigns_price
                                            .value
                                    "
                                    label="Price To Sell Recurring Four Amp Campaigns"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_recurring_four_content_campaigns_price'
                                        )
                                    "
                                    :hint="
                                        recurringFourContentCampaignsPriceHint
                                    "
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-card-title
                            v-if="canSeeClientPremiumPrice"
                            class="pl-0 pb-2"
                        >
                            Premium Prices
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        href="/app/premium"
                                        target="_blank"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>
                                            arrow-up-right-from-square
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Click to land on Premium Sales Page</span>
                            </v-tooltip>
                        </v-card-title>
                        <v-row v-if="canSeeClientPremiumPrice">
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_premium_amp_credit_price
                                            .value
                                    "
                                    label="Client Premium Price"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_premium_amp_credit_price'
                                        )
                                    "
                                    :hint="premiumPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_recurring_premium_amp_credit_price
                                            .value
                                    "
                                    label="Client Recurring Premium Price"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_recurring_premium_amp_credit_price'
                                        )
                                    "
                                    :hint="recurringPremiumPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-card-title
                            v-if="canSeeClientDefiPrice"
                            class="pl-0 pb-2"
                        >
                            DeFi Prices
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        href="/app/defi-distribution"
                                        target="_blank"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>
                                            arrow-up-right-from-square
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Click to land on DeFi Sales Page</span>
                            </v-tooltip>
                        </v-card-title>
                        <v-row v-if="canSeeClientDefiPrice">
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_defi_credit_price
                                            .value
                                    "
                                    label="Client DeFi Price"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_defi_credit_price'
                                        )
                                    "
                                    :hint="defiPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_recurring_defi_credit_price
                                            .value
                                    "
                                    label="Client Recurring DeFi Price"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_recurring_defi_credit_price'
                                        )
                                    "
                                    :hint="recurringDefiPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-card-title
                            v-if="canSeeClientBlowPrice"
                            class="pl-0 pb-2"
                        >
                            B.L.O.W. Prices
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        href="/app/blow"
                                        target="_blank"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>
                                            arrow-up-right-from-square
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    Click to land on B.L.O.W. Sales Page
                                </span>
                            </v-tooltip>
                        </v-card-title>
                        <v-row v-if="canSeeClientBlowPrice">
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing.reseller_client_blow_pack_price
                                            .value
                                    "
                                    label="Client B.L.O.W. Price"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_blow_pack_price'
                                        )
                                    "
                                    :hint="blowPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_recurring_blow_pack_price
                                            .value
                                    "
                                    label="Client Recurring B.L.O.W. Price"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_recurring_blow_pack_price'
                                        )
                                    "
                                    :hint="recurringBlowPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-card-title
                            v-if="canSeeClientMsnPrice"
                            class="pl-0 pb-2"
                        >
                            MSN Prices
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        href="/app/msn"
                                        target="_blank"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>
                                            arrow-up-right-from-square
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Click to land on MSN Sales Page</span>
                            </v-tooltip>
                        </v-card-title>
                        <v-row v-if="canSeeClientMsnPrice">
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing.reseller_client_msn_price.value
                                    "
                                    label="Client Microsoft MSN Distribution Price"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_msn_price'
                                        )
                                    "
                                    :hint="msnPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-input
                                    v-model="
                                        pricing
                                            .reseller_client_recurring_msn_price
                                            .value
                                    "
                                    label="Client Recurring Microsoft MSN Distribution Price"
                                    :rules="
                                        priceValidationFor(
                                            'reseller_client_recurring_msn_price'
                                        )
                                    "
                                    :hint="recurringMsnPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="mx-2 pb-4 pt-0">
                        <v-btn
                            type="submit"
                            color="primary"
                            :loading="isSaving"
                            :block="$vuetify.breakpoint.smAndDown"
                        >
                            Save
                        </v-btn>
                        <v-spacer
                            v-if="subDomainUpdated"
                            :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }"
                        />
                        <a
                            v-if="subDomainUpdated"
                            :href="subdomainLoginLink"
                            :class="{
                                'text-center': $vuetify.breakpoint.smAndDown
                            }"
                        >
                            Please Login to Your New Subdomain
                        </a>
                    </v-card-actions>
                </a-form>
            </v-col>
        </v-row>
    </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { isClientCabin } from '@/utils/helpers';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import type { WhitelabelData } from '@/types/Whitelabel';

const ResellerProps = Vue.extend({
    name: 'ResellerOptions',
    props: {
        reseller: {
            type: Object as PropType<Partial<WhitelabelData>>,
            default(): Partial<WhitelabelData> {
                return {};
            }
        }
    }
});

type CurrencyOption = {
    value: string;
    label: string;
};

@Component({
    components: {
        AForm,
        ATextInput
    }
})
export default class ResellerOptions extends ResellerProps {
    business_name = '';
    product_name = '';
    subdomain = '';
    reseller_booking_url = '';
    reseller_currency = 'USD';
    currencies: Record<string, string> = {};

    hasPremiumPurchase = false;

    isClientCabin = isClientCabin();

    pricing = {
        reseller_client_authoring_credit_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_recurring_authoring_credit_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_snapshot_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_premium_amp_credit_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_recurring_premium_amp_credit_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_defi_credit_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_recurring_defi_credit_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_blow_pack_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_recurring_blow_pack_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_msn_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_recurring_msn_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_content_campaign_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_recurring_content_campaign_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        },
        reseller_client_recurring_four_content_campaigns_price: {
            value: 0,
            minimum: 0,
            recommended: 0
        }
    };

    credits = {
        premium: 0,
        crypto: 0,
        blow: 0,
        msn: 0
    };

    isSaving = false;
    subDomainUpdated = false;

    get subdomainHint() {
        return `This will be the first component of your portal's URL. By default, all whitelabel portals are hosted on ${this.siteToRedirectTo}, so your URL will be: http://my_subdomain.${this.siteToRedirectTo}`;
    }

    get salesFunnelUrl() {
        if (this.subdomain) {
            return `https://${this.salesFunnelDomain}`;
        }

        return '';
    }

    get salesFunnelDomain() {
        if (this.subdomain) {
            return [this.subdomain, this.siteToRedirectTo].join('.');
        }

        return '';
    }

    get clientAuthoringPriceHint() {
        return 'This will be the price that your clients pay when they request an amp on your Amp Sales Funnel. This includes authoring and distribution.';
    }

    get premiumPriceHint() {
        return this.sentence(
            this.recommended(
                this.priceByCurrency(
                    this.pricing.reseller_client_premium_amp_credit_price
                        .recommended
                )
            ),
            'This will be the price that your clients pay for Premium distribution on your Premium Sales Page.'
        );
    }

    get recurringPremiumPriceHint() {
        return this.sentence(
            this.recommended(
                `${this.priceByCurrency(
                    this.pricing
                        .reseller_client_recurring_premium_amp_credit_price
                        .recommended
                )}/mo`
            ),
            'This will be the price that your clients pay for recurring Premium distribution on your Premium Sales Page.'
        );
    }

    get defiPriceHint() {
        return this.sentence(
            this.recommended(
                this.priceByCurrency(
                    this.pricing.reseller_client_defi_credit_price.recommended
                )
            ),
            'This will be the price that your clients pay for DeFi distribution on your DeFi Sales Page.'
        );
    }

    get recurringDefiPriceHint() {
        return this.sentence(
            this.recommended(
                `${this.priceByCurrency(
                    this.pricing.reseller_client_recurring_defi_credit_price
                        .recommended
                )}/mo`
            ),
            'This will be the price that your clients pay for recurring DeFi distribution on your DeFI Sales Page.'
        );
    }

    get blowPriceHint() {
        return this.sentence(
            this.recommended(
                this.priceByCurrency(
                    this.pricing.reseller_client_blow_pack_price.recommended
                )
            ),
            'This will be the price that your clients pay for a B.L.O.W. pack on your B.L.O.W Sales Page'
        );
    }

    get recurringBlowPriceHint() {
        return this.sentence(
            this.recommended(
                `${this.priceByCurrency(
                    this.pricing.reseller_client_recurring_blow_pack_price
                        .recommended
                )}/mo`
            ),
            'This will be the price that your clients pay for a recurring B.L.O.W. pack on your B.L.O.W Sales Page'
        );
    }

    get msnPriceHint() {
        return this.sentence(
            this.recommended(
                this.priceByCurrency(
                    this.pricing.reseller_client_msn_price.recommended
                )
            ),
            'This will be the price that your clients pay for MSN Distribution through your Sales Page'
        );
    }

    get recurringMsnPriceHint() {
        return this.sentence(
            this.recommended(
                `${this.priceByCurrency(
                    this.pricing.reseller_client_recurring_msn_price.recommended
                )}/mo`
            ),
            'This will be the price that your clients pay for recurring MSN Distribution through your Sales Page'
        );
    }

    get contentCampaignPriceHint() {
        return this.sentence(
            this.recommended(
                this.priceByCurrency(
                    this.pricing.reseller_client_content_campaign_price
                        .recommended
                )
            ),
            'This will be the price for One Time Amp Campaign through your store'
        );
    }

    get recurringContentCampaignPriceHint() {
        return this.sentence(
            this.recommended(
                `${this.priceByCurrency(this.pricing.reseller_client_recurring_content_campaign_price.recommended)}/mo`
            ),
            'This will be the price for 1 Amp Campaign per month sold through your store'
        );
    }

    get recurringFourContentCampaignsPriceHint() {
        return this.sentence(
            this.recommended(
                `${this.priceByCurrency(this.pricing.reseller_client_recurring_four_content_campaigns_price.recommended)}/mo`
            ),
            'This will be the price for 4 Amp campaigns per month sold through your store'
        );
    }

    get siteToRedirectTo() {
        return 'clientcabin.com';
    }

    get currencyOptions() {
        if (this.currencies) {
            return Object.entries(this.currencies).reduce(
                (acc: CurrencyOption[], [key, value]) => {
                    if (key !== 'default') {
                        acc.push({
                            value: key,
                            label: value
                        });
                    }

                    return acc;
                },
                []
            );
        }

        return [];
    }

    get canSeeClientPremiumPrice() {
        return this.hasPremiumPurchase;
    }

    // TODO (Aleksey) - double check this with Chris/Ken
    get canSeeClientDefiPrice() {
        return Number(this.credits.crypto) > 0;
    }

    get canSeeClientBlowPrice() {
        return Number(this.credits.blow) > 0;
    }

    get canSeeClientMsnPrice() {
        return Number(this.credits.msn) > 0;
    }

    get subdomainLoginLink() {
        return `${this.salesFunnelUrl}/users/login`;
    }

    get portal() {
        return '/profile/whitelabel/portal';
    }

    mounted() {
        const {
            business_name = '',
            product_name = '',
            subdomain = '',
            reseller_booking_url = '',
            reseller_currency = 'USD',
            currencies = {},
            pricing,
            credits,
            hasPremiumPurchase = false
        } = this.reseller;

        if (pricing) {
            this.pricing = pricing;
        }

        if (credits) {
            this.credits = credits;
        }

        this.business_name = business_name;
        this.product_name = product_name;
        this.subdomain = subdomain;
        this.reseller_booking_url = reseller_booking_url;
        this.reseller_currency = reseller_currency ?? this.reseller_currency;
        this.currencies = currencies;

        this.hasPremiumPurchase = hasPremiumPurchase;
    }

    save() {
        this.setSaving();

        return this.setUserData()
            .then(data => {
                if (!data?.meta?.success) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save Whitelabel Options. Please check the form for errors.'
                    );

                    return false;
                }

                this.onSave(data);

                this.$store.dispatch(
                    'notification/success',
                    'Whitelabel Options updated'
                );
            })
            .finally(this.setSaving.bind(this, false));
    }

    setSaving(isSaving = true) {
        this.$emit('saving', isSaving);
        this.isSaving = isSaving;
    }

    setUserData() {
        return this.$http
            .post('/users/panel', this.getDataToSave())
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    getDataToSave() {
        return (({
            business_name,
            product_name,
            subdomain,
            reseller_booking_url,
            reseller_currency,
            pricing: {
                reseller_client_authoring_credit_price: {
                    value: reseller_client_authoring_credit_price
                }
            },
            pricing: {
                reseller_client_recurring_authoring_credit_price: {
                    value: reseller_client_recurring_authoring_credit_price
                }
            },
            pricing: {
                reseller_client_snapshot_price: {
                    value: reseller_client_snapshot_price
                }
            },
            pricing: {
                reseller_client_premium_amp_credit_price: {
                    value: reseller_client_premium_amp_credit_price
                }
            },
            pricing: {
                reseller_client_recurring_premium_amp_credit_price: {
                    value: reseller_client_recurring_premium_amp_credit_price
                }
            },
            pricing: {
                reseller_client_defi_credit_price: {
                    value: reseller_client_defi_credit_price
                }
            },
            pricing: {
                reseller_client_recurring_defi_credit_price: {
                    value: reseller_client_recurring_defi_credit_price
                }
            },
            pricing: {
                reseller_client_blow_pack_price: {
                    value: reseller_client_blow_pack_price
                }
            },
            pricing: {
                reseller_client_recurring_blow_pack_price: {
                    value: reseller_client_recurring_blow_pack_price
                }
            },
            pricing: {
                reseller_client_msn_price: { value: reseller_client_msn_price }
            },
            pricing: {
                reseller_client_recurring_msn_price: {
                    value: reseller_client_recurring_msn_price
                }
            },
            pricing: {
                reseller_client_content_campaign_price: {
                    value: reseller_client_content_campaign_price
                }
            },
            pricing: {
                reseller_client_recurring_content_campaign_price: {
                    value: reseller_client_recurring_content_campaign_price
                }
            },
            pricing: {
                reseller_client_recurring_four_content_campaigns_price: {
                    value: reseller_client_recurring_four_content_campaigns_price
                }
            }
        }) => ({
            business_name,
            product_name,
            subdomain,
            reseller_booking_url,
            reseller_currency,
            reseller_client_authoring_credit_price,
            reseller_client_recurring_authoring_credit_price,
            reseller_client_snapshot_price,
            reseller_client_premium_amp_credit_price,
            reseller_client_recurring_premium_amp_credit_price,
            reseller_client_defi_credit_price,
            reseller_client_recurring_defi_credit_price,
            reseller_client_blow_pack_price,
            reseller_client_recurring_blow_pack_price,
            reseller_client_msn_price,
            reseller_client_recurring_msn_price,
            reseller_updating_profile: 1,
            reseller_client_content_campaign_price,
            reseller_client_recurring_content_campaign_price,
            reseller_client_recurring_four_content_campaigns_price
        }))(this);
    }

    onSave(data: { data?: Record<string, string> }) {
        if (data?.data?.reseller_subdomain_updated) {
            this.subDomainUpdated = true;
        }

        this.$emit('updated', this.getDataToSave());
    }

    sentence(...parts: Array<string | boolean>) {
        return parts.filter(Boolean).join(' ');
    }

    recommended(price: string | number | 0) {
        return `Recommended price: ${price}.`;
    }

    priceByCurrency(price: number) {
        if (price) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.reseller_currency || 'USD',
                maximumFractionDigits: 0
            }).format(price);
        }

        return price;
    }

    priceValidationFor(field: keyof WhitelabelData['pricing']) {
        return `required|min_value:${this.pricing[field].minimum}|max_value:20000`;
    }
}
</script>

<style lang="scss" scoped>
.shrink-overflow::v-deep {
    overflow: hidden;
    max-width: 100%;

    .v-btn__content {
        display: block;
        max-width: 100%;

        .no-overflow {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
