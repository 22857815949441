import SectionLoading from './SectionLoading.vue';
import PersonalInformation from './PersonalInformation.vue';
import Security from './Security.vue';
import Credits from './Credits.vue';
import Payments from './Payments.vue';
import Subscriptions from './Subscriptions.vue';
import Whitelabel from './Whitelabel/Whitelabel.vue';
import Companies from './Companies/Companies.vue';

export default {
    SectionLoading,
    PersonalInformation,
    Security,
    Credits,
    Payments,
    Subscriptions,
    Whitelabel,
    Companies
};
