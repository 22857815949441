<template>
    <section-loading v-if="isLoading" />
    <v-card
        v-else-if="canRender"
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-card-title>Subscriptions</v-card-title>
        <v-card-text :class="{ 'px-0': $vuetify.breakpoint.mdAndUp }">
            <v-simple-table v-if="$vuetify.breakpoint.mdAndUp">
                <template #default>
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th class="text-right">Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(subscription, i) in active" :key="'a' + i">
                            <td>
                                {{ subscription.product.product_name }}
                            </td>
                            <td class="text-right">
                                <v-chip
                                    v-if="!subscription.is_limited_term"
                                    small
                                    color="info"
                                    class="float-right"
                                >
                                    Next Bill Date:
                                    {{
                                        formatDate(subscription.next_bill_date)
                                    }}
                                    <span
                                        v-if="subscription.rebill_amount"
                                        class="pl-1"
                                    >
                                        (Amount: ${{
                                            subscription.rebill_amount
                                        }})
                                    </span>
                                </v-chip>
                            </td>
                        </tr>
                        <tr
                            v-for="(subscription, i) in canceled"
                            :key="'c' + i"
                        >
                            <td>
                                {{ subscription.product.product_name }}
                            </td>
                            <td class="text-right">
                                <v-chip
                                    small
                                    color="red"
                                    text-color="white"
                                    class="float-right"
                                >
                                    Expired&nbsp;on:
                                    {{ formatDate(subscription.end_time) }}
                                </v-chip>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div v-else>
                <v-list-item
                    v-for="(subscription, i) in active"
                    :key="'a' + i"
                    two-line
                    class="px-0"
                >
                    <v-list-item-content>
                        <v-list-item-title class="text-wrap py-2">
                            {{ subscription.product.product_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-chip small color="info">
                                Next Bill Date:
                                {{ formatDate(subscription.next_bill_date) }}
                            </v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    v-for="(subscription, i) in canceled"
                    :key="'c' + i"
                    two-line
                    class="px-0"
                >
                    <v-list-item-content>
                        <v-list-item-title class="text-wrap py-2">
                            {{ subscription.product.product_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-chip small color="red" text-color="white">
                                Expired&nbsp;on:
                                {{ formatDate(subscription.end_time) }}
                            </v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import SectionLoading from './SectionLoading.vue';

import type { SubscriptionsData } from '@/types/Panel';

@Component({
    components: {
        SectionLoading
    }
})
export default class Subscriptions extends Vue {
    subscriptions: SubscriptionsData = {
        available: false,
        active: [],
        canceled: []
    };

    isLoading = true;

    get canRender() {
        return (
            Boolean(this.subscriptions.active.length) ||
            Boolean(this.subscriptions.canceled.length)
        );
    }

    mounted() {
        this.setLoading();

        return this.getUserData()
            .then(({ data }) => {
                if (data) {
                    this.subscriptions = data.subscriptions;
                }
            })
            .finally(this.setLoading.bind(this, false));
    }

    setLoading(isLoading = true) {
        this.$emit('loading', isLoading);
        this.isLoading = isLoading;
    }

    get active() {
        return this.subscriptions.active.filter(subscription => {
            return subscription.product.name !== '';
        });
    }

    get canceled() {
        return this.subscriptions.canceled.filter(subscription => {
            return subscription.product.name !== '';
        });
    }

    formatDate(value: number) {
        return value ? this.$dayjs.unix(value).format('ll') : '';
    }

    getUserData() {
        return this.$http
            .get('/users/panel')
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }
}
</script>
